import http from "../lib/http";

// const url = "https://app12.nimsoft.net/api/bookingtest";
// const url = "https://app12.nimsoft.xyz/api/bookingtest";
// const url = "http://localhost:8080/bookingtest";
// const url = "https://api12.nimsoft.xyz/bookingtest";
const url = 'https://api12.nimsoft.net/bookingtest';


const getBooked = (seq: number) =>
  http.get(url + "/getBooked", { params: { seq } });

const getNextBookNo = () => http.get(url + "/getNextBookNo");

const getDriver = () => {
  return http.get(url + "/getDriver");
};

const getStation = () => {
  return http.get(url + "/getStation");
};

const getSearch = (data: any) => {
  return http.get(url + "/getSearch", {
    params: data,
  });
};

const getTruckRegister = () => {
  return http.get(url + "/getTruckRegister");
};

const getTodos = () => http.get(url + "/getTodos");

const test = () => {
  return http.get(url + "/test");
};

const testFC = () => http.get(url + "/testFC");

const booking = (data: any) => {
  return http.post(url + "/booking", data);
};

const cancelBooked = (data: any) => http.put(url + "/cancelBooked", data);

const updateBookStatus = (data: any) =>
  http.put(url + "/updateBookStatus", data);

export default {
  booking,
  cancelBooked,
  getBooked,
  getNextBookNo,
  getDriver,
  getTruckRegister,
  getStation,
  getSearch,
  getTodos,
  test,
  testFC,
  updateBookStatus,
};
